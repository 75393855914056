// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-code-not-activated-index-tsx": () => import("./../../../src/pages/code-not-activated/index.tsx" /* webpackChunkName: "component---src-pages-code-not-activated-index-tsx" */),
  "component---src-pages-code-not-found-index-tsx": () => import("./../../../src/pages/code-not-found/index.tsx" /* webpackChunkName: "component---src-pages-code-not-found-index-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-de-business-index-tsx": () => import("./../../../src/pages/de/business/index.tsx" /* webpackChunkName: "component---src-pages-de-business-index-tsx" */),
  "component---src-pages-de-lost-and-found-index-tsx": () => import("./../../../src/pages/de/lost-and-found/index.tsx" /* webpackChunkName: "component---src-pages-de-lost-and-found-index-tsx" */),
  "component---src-pages-de-medizinische-geraetehersteller-index-tsx": () => import("./../../../src/pages/de/medizinische-geraetehersteller/index.tsx" /* webpackChunkName: "component---src-pages-de-medizinische-geraetehersteller-index-tsx" */),
  "component---src-pages-de-medizinisches-geraet-index-tsx": () => import("./../../../src/pages/de/medizinisches-geraet/index.tsx" /* webpackChunkName: "component---src-pages-de-medizinisches-geraet-index-tsx" */),
  "component---src-pages-de-qrpid-waiter-lockdown-angebot-demo-index-tsx": () => import("./../../../src/pages/de/qrpid-waiter-lockdown-angebot/demo/index.tsx" /* webpackChunkName: "component---src-pages-de-qrpid-waiter-lockdown-angebot-demo-index-tsx" */),
  "component---src-pages-de-qrpid-waiter-lockdown-angebot-index-tsx": () => import("./../../../src/pages/de/qrpid-waiter-lockdown-angebot/index.tsx" /* webpackChunkName: "component---src-pages-de-qrpid-waiter-lockdown-angebot-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-version-tsx": () => import("./../../../src/pages/version.tsx" /* webpackChunkName: "component---src-pages-version-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

